<template>
  <b-modal
    visible
    scrollable
    centered
    size="s"
    :title="'Импорт партнерских услуг'"
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div v-else>
      <b-row>
        <b-col>
          <b-form-select
            v-model="legalPartnerId"
            value-field="id"
            text-field="legalName"
            label="legalName"
            trim
            :options="optionsLegalPartner"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-file
            v-model="importFile"
            class="mt-3"
            plain
          />
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          :disabled="!importFile || !legalPartnerId"
          @click="onClickImport"
        >
          Импорт
        </b-button>
        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Loading from '@/components/Loading';
import { api } from '@/helpers/api';

export default {
  name: 'ImportPartnerService',
  components: {
    Loading,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      importFile: null,
      optionsLegalPartner: [],
      legalPartnerId: null,
    };
  },
  computed: {
    legalPartnerListStore() {
      return this.$store.state.Laboratories.legalPartnerList;
    },
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, {});
    this.optionsLegalPartner = this.legalPartnerListStore.data;
    this.isLoading = false;
  },
  methods: {
    async onClose() {
      this.$emit('input', false);
    },
    async onClinicReestrImport() {
      const params = {
        ...this.params,
      };
      await api.post('v2/clinic/services/import', params);
      this.onClose();
    },
    async onClickImport() {
      const params = {
        legalPartnerGuid: this.legalPartnerId,
        importFile: this.importFile,
      };
      await this.$store.dispatch(this.$types.LABORATORY_PARTNER_SERVICE_IMPORT, params);
      await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNER_SERVICES, this.params);
      // await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNERS, this.params);
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
